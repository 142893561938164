html {
  height: 100vh;
}

body {
  min-height: 100vh;
}

html, body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

#root {
  width: 100%;
  min-height: 100vh;
}

.App, .app>div {
  text-align: center;
  justify-content: space-evenly;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

#main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#vs {
  min-height: 100vh;
}

/* standard colors are listed here
* dark gray: #18191A
*/

/* CSS for each component is below in alphabetical order */

/* ---Footer--- */
#footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #242621;
}

#contactRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#socialDiv {
  box-sizing: border-box;
  padding: 10px;
}

#emailDiv {
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text {
  color: #C7C7C7;
  font-size: 14px;
}

.contactText {
  margin: 0px;
  text-align: left;
}

.linkText {
  text-decoration: none;
}

.linkText:hover {
  color: #D3D3D3;
}

.socialImg {
  margin: 5px;
}

.socialImg:hover {
  filter: invert(20%);
}

.pageBreak {
  box-sizing: border-box;
  width: 95%;
  height: 1px;
  border: none;
  background-color: #C7C7C7;
}

/* ---Home--- */
#homePage {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #18191A;
  width: 100%;
  min-height: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  flex-grow: 1;
  padding-top: 10vh;
}

#intro {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

#fpBlog {
  width: 70%;
}

#profile-pic {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#ppimg {
  border: transparent;
  border-radius: 50px;
  max-height: 30vh;
  padding: 1vh;
  padding: 20px;
}

#desc {
  color: #C7C7C7;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#hello {
  font-size: 80px; 
  margin-bottom: 10px;
  margin-top: 10px;
  color: #C7C7C7;
}

.fptext {
  font-size: 25px;
  margin: 10px;
}

/* ---NavBar--- */
#navBar {
  position: fixed;
  box-sizing: border-box;
  border-bottom-color: #C7C7C7;
  border-bottom-width: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: #242621;
  padding: 10px;
  margin: 0px;
}

.homeText {
  color: #C7C7C7;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
}

.homeMainButton {
  color: #C7C7C7;
  background: transparent;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  border: solid;
  border-color: #C7C7C7;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.homeMainButton:hover {
  color: #D3D3D3;
  border-color: #D3D3D3;
}

#titleLink {
  color: #C7C7C7;
  text-decoration: none;
  font-family: 'Courier New', monospace;
  font-size: 30px;
  font-weight: bold;
  max-width: 100%;
}

#titleLink:hover {
  color: #D3D3D3;
}

#navMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.menuItem {
  box-sizing: border-box;
  color: #C7C7C7;
  padding-left: 20px;
  padding-right: 20px;
  font-family: monospace;
  font-size: 20px;
}

/* Home and Navbar Components */
@media only screen and (max-width: 1250px){

  #titleLink {
    font-size: 30px;
  }
  
  .menuItem {
    font-size: 20px;
  }
  
  #homePage {
    flex-direction: column;
    padding-top: 10vh;
  }

  #intro {
    width: 100%;
    height: auto;
  }

  #fpBlog {
    width: 100%;
  }
  
  #hello {
    font-size: 50px; 
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  #desc {
    max-width: 80%;
  }

  #profile-pic {
    flex-direction: column;
  }
  
  .fptext {
    font-size: 20px;
  }
    
}

@media only screen and (max-width: 450px){

  #homePage {
    padding-top: 20vh;
  }

}

/* ---Photography--- */
#photographyPage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #18191A;
  width: 100%;
  min-height: 100vh;
  flex-grow: 1;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10vh;
}

.mainText {
  color: #C7C7C7;
  font-family: monospace;
  font-size: 20px;
  margin-top: 20px;
}

#instaGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.instaPic {
  margin: 20px;
  border: solid;
}

/* ---Blog--- */
#blogPage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #18191A;
  width: 100%;
  min-height: 100vh;
  flex-grow: 1;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10vh;
  margin: 0px;
}

.blogCard {
  display: flex;
  flex-direction: row;
  max-width: 95vw;
}

.blog_list_img {
  height: 150px;
  width: 240px;
  overflow: hidden;
  margin: 20px;
  max-height: 90vw;
}

.blog_list_img:hover {
  opacity: 0.3;
}

.blog_card_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.cardTitle {
  color: #C7C7C7;
  font-family: Helvetica, sans-serif;
  font-size: 25px;
  margin-bottom: 5px;
  margin-top: 20px;
  text-decoration: none;
  text-align: left;
}

.cardTitle:hover {
  color: #D3D3D3;
}

.cardText {
  color: #C7C7C7;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  margin: 5px;
  text-align: left;
}

.dateText {
  color: #C7C7C7;
  font-family: Helvetica, sans-serif;
  font-size: 12px;
  margin: 5px;
  text-align: left;
}

@media only screen and (max-width: 1250px) {
  
  .blogCard {
    display: flex;
    flex-direction: column;
  }

  #blogPage {
    padding-left: 3%;
    padding-right: 3%;
  }

}

@media only screen and (max-width: 400px) {

  .blog_list_img {
    width: 300px;
    overflow: hidden;
    margin: 20px;
    max-height: 90vw;
  }

}

/* ---Article--- */

#articlePage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #18191A;
  min-height: 100vh;
  width: 100%;
  flex-grow: 1;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10vh;
}

.articleTitle{
  color: #C7C7C7;
  font-family: Helvetica, sans-serif;
  font-size: 60px;
  margin-bottom: 20px;
}

.articleText {
  color: #C7C7C7;
  font-family: Arial;
  font-size: 20px;
  margin: 5px;
  font-style: italic;
}

.post_date {
  margin-top: 10px;
  align-self: flex-start;
  font-size: 15px;
}

.article_headimg{
  max-height: 80vh;
  max-width: 60vw;
  margin: 20px;
}

.articleContent{
  display: flex;
  flex-direction: column;
  max-width: 60vw;
  justify-content: flex-start;
  text-align: left;
}

@media only screen and (max-width: 1250px) {
  
  .articleContent{
    max-width: 90vw;
  }

}

.articleMarkdown{
  color: #C7C7C7;
  font-family: Helvetica, sans-serif;
  line-height: 2;
}

.dates{
  display: flex;
  flex-direction: row;
}

#authorDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#authorInfo{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.authorText{
  color: #C7C7C7;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  margin: 0px;
}

.smallPP{
    border: transparent;
    border-radius: 100px;
    max-height: 100px;
    margin: 20px;
}